header {
    text-align: center;
    background-color: #444;
}

header .container {
    display: flex;
    justify-content: space-between;
}

header a {
    color: #ddd;
    text-decoration: none;
    display: inline-block;
    padding: 15px;
}

header a.active,
header a:hover {
    background-color: #282c34 !important;
}

header a.logout {
    background-color: #b91c1c;
}
header a.logout:hover {
    background-color: #dc2626 !important;
}