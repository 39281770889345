.heading {
    width: 100%;
    display: flex;
    padding-bottom: 20px;
}

.heading h2 {
    margin: 0;
}

.heading .headingLeft,
.heading .headingRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heading .headingRight > div {
    display: flex;
    justify-content: flex-end;
}

input[type=submit] {
    margin-top: 5px;
}