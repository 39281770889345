body {
    font-size: 18px;
    color: #282c34;
    background-color: white;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 1280px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 auto;
}

table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
}
table thead th {
    border-bottom: 3px solid #dee2e6;
}
table th,
table td {
    text-align: left;
    padding: 0.75rem;
}
table td {
    border-bottom: 1px solid #dee2e6;
}
table tbody tr:hover td {
    background-color: #dee2e6;
}
table.linked tbody tr {
    cursor: pointer;
}

.button {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #444;
    border: 0;
    border-radius: 4px;
}
.button:hover {
    background-color: #282c34;
}

.button.light {
    background-color: #888;
}
.button.light {
    background-color: #777;
}