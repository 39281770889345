.opportunity-overview-head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.opportunity-overview-head > div {
    flex: 1;
}
.opportunity-overview-head .opportunity-overview-head-opportunities {
    font-size: 0.85em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inter-exchange-selector,
.time-span {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.datetime {
    min-height: 38px;
    margin-left: 8px;
    padding-left: 5px;
    padding-right: 5px;
}


.opportunities-table {
    font-size: 14px;
}
.opportunity-rate-row {
    margin-bottom: 5px;
}